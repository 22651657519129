<template>
  <div class="orgnization">
    <div class="searchContent">
      <el-form :model="formData" :inline="true" label-width="10px">
        <div class="searchBox">
          <el-form-item>
            <el-input
              placeholder="请输入主体名称"
              v-model="formData.searchValue"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="formData.cityCode"
              @change="getPlace(1)"
              placeholder="请选择市"
            >
              <el-option
                v-for="(item, index) in cityList"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="formData.areaCode" placeholder="请选择区/县">
              <el-option
                v-for="(item, index) in areaList"
                :key="index"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-right: 0px">
            <el-button icon="el-icon-search" @click="submitSearch"
              >搜索</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="tablContent">
      <el-table
        :data="dataList"
        highlight-current-row
        @row-click="clickRow"
        style="width: 100%"
      >
        <el-table-column
          align="left"
          label="主体名称"
          prop="companyName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="服务品种"
          prop="serviceVariety"
          width="200"
          :formatter="optServiceVariety"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="服务类型"
          prop="serviceType"
          width="200"
          :formatter="optServiceType"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="联系地址"
          width="400"
          :formatter="optArea"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          label="联系人"
          prop="contacts"
          width="100"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column label="认证状态" prop="" width="120" align="center">
          <template slot-scope="scopes">
            <span style="color: red" v-if="scopes.row.checkStatus === 0"
              >未认证</span
            >
            <span style="color: green" v-if="scopes.row.checkStatus === 1"
              >已认证</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pageContent">
        <el-pagination
          v-show="total > 0"
          @size-change="clickChange(1)"
          @current-change="clickChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-sizes="[10, 20, 30, 50]"
          :page-size.sync="formData.pageSize"
          :current-page.sync="formData.pageNum"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { getPlace, organizationList } from "@/api/serviechall";

export default {
  data() {
    return {
      formData: {
        searchValue: "",
        provinceCode: 43,
        cityCode: "",
        countyCode: "",
        ascDesType: 0,
        pageNum: 1,
        pageSize: 10,
        orderType: 1,
      },
      cityList: [],
      areaList: [],
      dataList: [],
      total: 0,
    };
  },
  methods: {
    getPlace(code) {
      if (code === 1) {
        this.formData.countyCode = "";
      }
      const { provinceCode, cityCode, countyCode } = this.formData;
      let query = {
        areaCode: countyCode,
        provinceCode,
        cityCode,
        streetCode: "",
      };
      getPlace(query).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.cityList = data.cityList;
          this.areaList = data.areaList ? data.areaList : [];
        }
      });
    },
    organizationList() {
      organizationList(this.formData).then((res) => {
        const { code, data } = res;
        if (code === 200) {
          this.dataList = data.pageInfo.list;
          this.total = data.pageInfo.total;
        }
      });
    },
    optServiceVariety(row, col, val) {
      return val ? val : "";
    },
    optServiceType(row, col, val) {
      return val ? val : "";
    },
    optArea(row) {
      const { province, city, county, town, village } = row;
      return (
        (province || "") +
        (city || "") +
        (county || "") +
        (town || "") +
        (village || "")
      );
    },
    submitSearch() {
      this.formData.pageNum = 1;
      this.formData.pageSize = 10;
      this.organizationList();
    },
    clickChange(code) {
      if (code === 1) {
        this.formData.pageNum = 1;
      }
      this.organizationList();
    },
    clickRow(row) {
      let query = { id: row.id };
      let routeUrl = this.$router.resolve({
        path: "/portal/organizationDetail",
        query,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
  created() {
    this.getPlace();
    this.organizationList();
  },
  watch: {
    watchData: {
      deep: true,
      handler: function () {
        if (this.$store.state.formData.id == 4) {
          this.formData.searchValue = this.$store.state.formData.keyWord;
        }
      },
      immediate: true,
    },
  },
  computed: {
    watchData() {
      return this.$store.state.formData;
    },
  },
};
</script>
<style lang="scss" scoped>
.orgnization {
  width: 1396px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dddddd;
  margin-bottom: 18px;
  .searchBox {
    display: flex;
    justify-content: flex-end;
    /deep/ .el-button {
      background-color: #17a763;
      color: #fff;
    }
    .el-form-item {
      margin: 0 10px 0 0;
    }
  }
  .tablContent {
    /deep/ .el-table th.el-table__cell > .cell {
      font-size: 14px;
    }
    .pageContent {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      /deep/ .el-pager li:not(.disabled).active {
        background-color: #17a763;
        color: #fff;
      }
    }
  }
}
</style>